.PostBlock {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #36393f;
  color: white;
  padding: 2rem;
  margin: 2rem;
  border-radius: 50px 15px;
  min-width: 480px;
  max-width: 480px;
}

h1 {
  margin-top: 0;
  margin-bottom: 0.65rem;
  word-break: break-word;
}

h5 {
  margin-top: 0;
  font-weight: normal;
  word-break: break-word;
}

h3 {
  word-break: break-word;
}

#title,
#name,
#content,
#media {
  border-radius: 20px 20px 20px 20px;
  padding: 5px 10px 5px 10px;
  min-height: 30px;
  background-color: #606770;
  color: white;
  border: none;
  word-wrap: break-word;
}

#title::placeholder,
#name::placeholder,
#content::placeholder,
#media::placeholder {
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#title {
}

#name {
  margin: 0.5rem 0 2rem;
}

#content {
  min-height: 7rem;
  padding: 10px;
}

#media {
  margin: 0.5rem 0 2rem;
  margin-bottom: 1rem;
}

button {
  background-color: rgb(244, 129, 32);
  border-radius: 20px 20px 20px 20px;
  padding: 5px 10px 5px 10px;
  min-height: 20px;
  font-weight: bold;
  font-size: large;
}

hr {
  width: 95%;
}

.addComment {
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 20px 20px;
  padding: 5px 10px 12px 10px;
  background-color: #606770;
  color: white;
  border: none;
  word-wrap: break-word;
  margin-top: 1.5rem;
}

.addComment h3 {
  margin: 3px 0 5px 0;
  font-size: 1.05rem;
}

.addComment input,
.addComment textarea {
  background: transparent;
  border: none;
  color: white;
}

.addComment input {
  margin-bottom: 3px;
}

.addComment input::placeholder,
.addComment textarea::placeholder {
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.addComment button {
  background-color: rgb(245, 177, 88);
  border-radius: 20px 20px 20px 20px;
  padding: 5px 10px 5px 10px;
  font-weight: medium;
  font-size: small;
}

.commentBlock {
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 20px 20px;
  padding: 5px 10px 12px 10px;
  background-color: #606770;
  color: white;
  border: none;
  word-wrap: break-word;
  margin-bottom: 0.5rem;
}

.commentBlock p {
    margin: 0;
    padding: 0;
}
